import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostPreview from '../components/post-preview'
import LoadMore from '../components/load-more'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        postsPerPage
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] },
      filter: { frontmatter: { status: { eq: "published" }}}
    ) {
      edges {
        node {
          frontmatter {
            path
            slug
            title
            status
            published(formatString: "dddd Do MMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tags {
              slug
              name
            }
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`

class TagPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { page: props.pageContext.page || 1 }
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handlePopstate = this.handlePopstate.bind(this)
  }

  handleLoadMore() {
    const nextPage = this.state.page + 1
    const nextState = { page: nextPage }
    const to = `/tag/${this.props.pageContext.tag.slug}/page/${nextPage}/`

    window.history.pushState(nextState, null, to)

    this.setState(nextState)
  }

  handlePopstate() {
    this.setState({
      page: 1,
      ...(window.history.state || {}),
    })
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopstate)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate)
  }

  render() {
    const {
      data: {
        allMarkdownRemark: { edges },
        site: { siteMetadata: { title, postsPerPage }},
      },
      pageContext: { tag: { slug: tagSlug, name: tagName }},
      location,
    } = this.props

    const numberOfPosts = this.state.page * postsPerPage

    const allPosts = edges
      .filter(({ node: { frontmatter: { slug, status, tags }}}) => (
        status === 'published' &&
        slug !== 'about' &&
        slug !== 'places' &&
        tags.find(tag => tag.slug === tagSlug)
      ))

    const posts = allPosts
      .slice(0, numberOfPosts)
      .map(({ node }, i) => <PostPreview node={node} key={i} />)

    return (
      <Layout
        title={`${tagName} | ${title}`}
        pathname={`${location.pathname}${location.pathname.substr(-1) !== '/' ? '/' : ''}`}
        og={[
          { property: 'og:type', content: 'website' },
          { property: 'og:title', content: `${tagName} | ${title}` },
        ]}
        twitter={[
          { name: 'card', content: 'summary' },
          { name: 'title', content: `${tagName} | ${title}` },
        ]}
        prev={this.state.page > 1 ? `${location.pathname}${location.pathname.substr(-1) !== '/' ? '/' : ''}${this.state.page > 2 ? `page/${this.state.page - 1}/` : ''}` : null}
        next={allPosts.length > posts.length ? `${location.pathname}${location.pathname.substr(-1) !== '/' ? '/' : ''}page/${this.state.page + 1}/` : null}
      >
        <main role="main" className="post-list">
          {posts}
          {allPosts.length > numberOfPosts && <LoadMore handleClick={this.handleLoadMore} />}
        </main>
      </Layout>
    )
  }
}

export default TagPage